import { RouteSectionProps } from "@solidjs/router";

import DashboardLayout, { Tab } from "@repo/ui/dashboard/DashboardLayout";

export default function Dashboard({ children }: D<RouteSectionProps>) {
  return (
    <DashboardLayout
      tabs={
        <>
          <Tab path="/" long="Home" icon="" short="Home" />
          <Tab path="/users" long="User search" icon="" short="Users" />
          <Tab path="/manual-idv" long="Manual IDV" icon="" short="IDV" />
          {/* <Tab path="/account" long="Account" icon="" short="Account" /> */}
        </>
      }
      propsFor={{
        logo: {
          word: false,
          children: "Admin",
        },
      }}
    >
      {children}
    </DashboardLayout>
  );
}
